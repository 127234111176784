import isEqual from "lodash/isEqual";
import { PatchMode } from "./decryptPatcher";
import {
  decryptPatcherModelDefinition,
  encryptPatcherModelDefinition,
} from "./modelDefinition";

type Key = Key[] | string;
export type Keys = Array<Key>;
type SpreadKeys = Array<Key[]>;
export type KeysForSession = Array<Array<string>>;
export type EncryptionDefinition = {
  encryptedFields: Keys;
  keysForSession: KeysForSession;
  modelDefinition: { [root: string]: AnyObject };
  operationName: string;
};

function generateResults(
  currentData: any,
  result: SpreadKeys,
  currentKey: string,
  currentPath: Array<string>,
  keysForSession: KeysForSession = [],
): [SpreadKeys, KeysForSession] {
  if (currentData["session_key_context"] === true) {
    keysForSession.push(currentPath);
  }

  let nextResult: SpreadKeys = result.length
    ? result.map((path) => [...path, currentKey])
    : [[currentKey]];

  const currentFields = Object.keys(currentData).filter(
    (field) =>
      typeof currentData[field] === "boolean" &&
      field !== "session_key_context" &&
      currentData[field] === true,
  );

  const nextFields = Object.keys(currentData).filter(
    (field) => typeof currentData[field] === "object",
  );

  const cachedResults = [...nextResult];

  if (nextFields.length) {
    nextFields.forEach((field) => {
      const [nextFieldsResult] = generateResults(
        currentData[field],
        cachedResults,
        field,
        [...currentPath, field],
        keysForSession,
      );
      nextResult = [...nextResult, ...nextFieldsResult];
    });
  }

  if (currentFields.length) {
    currentFields.forEach((newField) => {
      const fieldPath = cachedResults.map((cached) => [...cached, newField]);
      nextResult = [...nextResult, ...fieldPath];
    });
  }

  return [nextResult, keysForSession];
}

// starts with the subarray
function hasSubArray(master: Key, sub: Key) {
  // return sub.every((i => v => (i = master.indexOf(v, i) + 1))(0));
  if (sub.length > master.length) return false;
  const masterSub = master.slice(0, sub.length);
  return isEqual(masterSub, sub);
}

const sortDecreasing = (pathArrayA: Key, pathArrayB: Key) =>
  pathArrayB.length - pathArrayA.length;

// [a,b,c] => [a, [b, [c]]]
function nest(array: Key[]): Key[] {
  if (array.length === 1) return [array[0]];

  return [array[0], nest(array.slice(1))];
}

function flattenResults(intermediateResults: SpreadKeys): Keys {
  let withoutSubarrays = intermediateResults.filter(
    (pathArray) =>
      intermediateResults.filter((otherPath) =>
        hasSubArray(otherPath, pathArray),
      ).length === 1,
  );

  withoutSubarrays = withoutSubarrays.sort(sortDecreasing);

  let denominators: SpreadKeys = [];

  withoutSubarrays.forEach((pathArray) => {
    let subarray: Key = [];
    for (let index = pathArray.length - 1; index > 0; index--) {
      subarray = pathArray.slice(0, index);
      const isDenominator =
        withoutSubarrays.filter((otherPath) => hasSubArray(otherPath, subarray))
          .length > 1;
      if (isDenominator) {
        const isNewDenominator = denominators.every(
          (denominator) => !isEqual(denominator, subarray),
        );
        if (isNewDenominator) denominators.push(subarray);
        break;
      }
    }
  });

  let normalizedPaths = withoutSubarrays;
  denominators = denominators.sort(sortDecreasing);

  denominators.forEach((denominator) => {
    const withDenominator = normalizedPaths.filter((pathArray) =>
      hasSubArray(pathArray, denominator),
    );

    // denominator = [a, b]
    // withDenominator = [[a, b, c], [a,b,d,e]]
    // specifics = [[c], [d,e]]
    const specifics = withDenominator.map((pathArray) =>
      pathArray.slice(denominator.length),
    );

    // denominator = [a, b]
    // specifics = [[c], [d,e,f]]
    // normalizedPaths = [a, b, [[c], [d,[e,[f]]]]]
    const specificsArray: Key[] = [];
    specifics.forEach((specific) => {
      if (specific.length === 1) specificsArray.push(specific);
      else specificsArray.push(nest(specific));
    });
    const normalizedPath: Key[] = [...denominator, specificsArray];

    // remove paths with denominator
    normalizedPaths = normalizedPaths.filter(
      (pathArray) => !hasSubArray(pathArray, denominator),
    );
    // add new normalized path
    normalizedPaths.push(normalizedPath);
  });

  // normalizedPaths = [[definition]] || [[definition], [...definitions]]
  if (normalizedPaths.length > 1) return flattenResults(normalizedPaths);
  return normalizedPaths[0];
}

export function createEncryptionDefinition(
  operationName: string,
  operationModelDefinition: AnyObject,
): EncryptionDefinition {
  const root = Object.keys(operationModelDefinition)[0];

  const rootModelDefinition = operationModelDefinition[root];

  const [intermediateResults, keysForSession] = generateResults(
    rootModelDefinition,
    [],
    root,
    [root],
  );
  const encryptedFields = flattenResults(intermediateResults);

  return {
    encryptedFields,
    keysForSession,
    modelDefinition: operationModelDefinition,
    operationName,
  };
}

export function getEncryptionDefinition(
  operationName: string,
  mode: PatchMode,
) {
  const modelDefinition =
    mode === "decrypt"
      ? decryptPatcherModelDefinition
      : encryptPatcherModelDefinition;

  const normalized: {
    [root: string]: EncryptionDefinition;
  } = Object.keys(modelDefinition).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: createEncryptionDefinition(
        cur,
        modelDefinition[cur as keyof typeof modelDefinition],
      ),
    };
  }, {});

  return normalized[operationName];
}
