import merge from "lodash/merge";
import { AnyObject, DeepNullableObj, EncryptedField } from "../types";

export { default as mockAccount } from "./mockAccount";
export { default as mockAuction } from "./mockAuction";
export { default as mockAuctionRequest } from "./mockAuctionRequest";
export { default as mockAuctionRequests } from "./mockAuctionRequests";
export { default as mockAuctions } from "./mockAuctions";
export { default as mockCareprovider } from "./mockCareprovider";
export { default as mockCareseeker } from "./mockCareseeker";
export { default as mockConsultant } from "./mockConsultant";
export { default as mockEvent } from "./mockEvent";
export { default as mockFile } from "./mockFile";
export { default as mockLegalDocuments } from "./mockLegalDocuments";
export { default as mockPatient } from "./mockPatient";
export { default as mockProviderSearchPatient } from "./mockProviderSearchPatient";
export { default as mockProviderSearchProvider } from "./mockProviderSearchProvider";
export { default as mockProviderSearchRequestOverview } from "./mockProviderSearchRequestOverview";
export { default as mockRequestsPayload } from "./mockRequestsPayload";
export { default as mockSearchMergePayload } from "./mockSearchMergePayload";
export { default as mockSearchMergeResources } from "./mockSearchMergeResources";

export function encryptedField(field: Partial<EncryptedField>) {
  return merge(
    {
      content: null,
      iv: null,
      seald_content: null,
      decrypted: null,
    },
    field,
  );
}

type MockedTypes =
  | "AcceptedTableRequest"
  | "Account"
  | "Auction"
  | "AuctionRequest"
  | "AuctionRequests"
  | "Auctions"
  | "Careprovider"
  | "Careseeker"
  | "CareseekerModule"
  | "Consultant"
  | "ContactedTableRequest"
  | "DeclinedTableRequest"
  | "Event"
  | "EventContext"
  | "File"
  | "FilteredTableFilteredCandidate"
  | "MedicalDiagnosis"
  | "ProviderSearchProvider"
  | "Patient"
  | "PatientNeeds"
  | "PatientProfile"
  | "PotentialReceiversTableCandidate"
  | "ReduxStore"
  | "RejectedTableRequest"
  | "Request"
  | "RequestsPayload"
  | "SearchMergePayload"
  | "ProviderSearchPatient"
  | "ValidatedTableRequest"
  | "ProviderSearchRequestsOverview"
  | "LegalDocuments";

type mockBooleans = Record<MockedTypes, boolean>;

type ArgsType = {
  __shallow?: Record<MockedTypes, mockBooleans> & {
    __bail: boolean;
  };
};

export type MockFn<T> = (args?: ArgsType & T) => any;
export type TypedMockFn<T> = (
  args?: ArgsType & Partial<DeepNullableObj<T>>,
) => T;

export const createMock = (
  sourceMock: MockedTypes,
  args: (AnyObject & ArgsType) | undefined,
  callback: (shallow: (targetMock: MockedTypes) => AnyObject) => any,
) => {
  if (args?.__shallow?.__bail) {
    return "";
  }

  const shallow = (targetMock: MockedTypes) => {
    // a type can't be mocked if it was already mocked by
    // the same parent type up in the call tree
    // otherwise it would create a circular call stack
    // is this chase it should bail by rendering an empty string
    const shouldBail =
      args?.__shallow &&
      args?.__shallow[sourceMock] &&
      args?.__shallow[sourceMock][targetMock];

    if (shouldBail) {
      return {
        __shallow: {
          __bail: true,
        },
      };
    }

    return {
      __shallow: {
        ...args?.__shallow,
        [sourceMock]: {
          ...(args?.__shallow ? args?.__shallow[sourceMock] : {}),
          [targetMock]: true,
        },
      },
    };
  };

  return callback(shallow);
};

export function mockFetch(status: number, response?: AnyObject) {
  return Promise.resolve({
    status,
    headers: {
      get: () => "",
    },
    json: response ? () => Promise.resolve(response) : undefined,
  });
}
